import router from '@/router';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';
import jwt_decode from "jwt-decode";

export const auth = {
    namespaced: true,
    state: {
        userId      : null,
        user        : null,
        token       : null
    },
    mutations: {
        setUserId( state, payload ) {
            state.userId = payload;
        },
        setUser( state, payload ) {
            state.user = payload;
        },
        setToken( state, payload ) {
            state.token = payload;
        }
    },
    actions: {
        logout({ commit }) {
            try {
                commit('setUserId', null);
                commit('setUser', null);
                commit('setToken', null);

                localStorage.removeItem('ahora-app-auth');

                router.push('/').catch((err) => {
                    if( err.name != 'NavigationDuplicated') throw err;
                });
                return Promise.resolve();
            }
            catch(error) {
                console.log(error);
                return Promise.reject();
            }
        },
        async login(_, { username, password }) {
            try {
                let api = new API();
                let res = await api.post('/signin', { username, password });

                if( res.data.status === true ) {
                    localStorage.setItem('ahora-app-auth', res.data.data);
                    if( _st.isNUE( res.data.nextStep ) ) {
                        router.push('/secure/home');
                        bus.$emit('login');
                    }
                    else if( res.data.nextStep == 'change' ) {
                        router.push('/cambiar-contrasena');
                    }
                }

                return Promise.resolve( res.data );
            }
            catch( error ) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async changePassword(_, { oldPassword, newPassword }) {
            try {
                let api = new API();
                let res = await api.post('/change-password', { 
                    current : oldPassword, 
                    password: newPassword 
                });
                
                return Promise.resolve( res );
            } 
            catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async changePasswordLogin(_, { username, password, token }) {
            try {
                let api = new API();
                let res = await api.post('/change-pwd-login', { username, password, token });

                if( res.data.status === true ) {
                    localStorage.setItem('ahora-app-auth', res.data.token);
                    router.push('/secure');
                    bus.$emit('login');
                }

                return Promise.resolve( res.data );
            }
            catch( error ) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async resetPassword({ commit, state }, password ) {
            try {
                let api = new API();
                let res = await api.post('/reset-password', { password });
      
                if( res.data.status === true ) {
                    let res2 = await api.get( `/user/${state.user.id}` );
        
                    if( res2.data.status === true ) {
                        commit( 'setUser', res2.data.data );
                    }
        
                    router.push('/secure');
                    // bus.$emit('login');
                }
        
                return Promise.resolve( res.data );
            }
            catch( error ) {
              console.log( error );
              return Promise.reject( error );
            }
          },
        async forgotPassword(_, email) {
            try {
                let api = new API();
                let res = await api.post('/forgot-password', { email });
                
                return Promise.resolve( res.data );
              } 
            catch(error) {
                return Promise.reject(error);
            }
        },
        async authAction({ commit, state, dispatch }) {
            let token = localStorage.getItem('ahora-app-auth');
            if( _st.isNUE( token ) ) {
                return Promise.resolve();
            }

            let decoded = jwt_decode( token );

            commit('setUserId', decoded.usr);
            commit('setToken', token);

            // axios.defaults.headers.common['Authorization'] = token;

            if( _st.isNUE( state.user ) || state.user.id != state.userId ) {
                try {
                    let api = new API();
                    let res = await api.get( `/user/${decoded.usr}` );
          
                    if( res.data.status === true ) {
                        commit( 'setUser', res.data.data );
                    }
          
                    return Promise.resolve();
                }
                catch(error) {
                    if( error.response.status === 401 ) {
                        dispatch('logout');
                        return Promise.resolve();
                    }
          
                    return Promise.reject(error);
                  }
            }
        }
    },
    getters: {
        user                : (state) => state.user,
		isLoggedIn          : (state) => !_st.isNUE( state.token ),
        token               : (state) => state.token
    }
}