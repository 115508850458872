import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  // PUBLIC ROUTES
  {
    path: '/content/:slug',
    name: 'Ahora',
    props: true,
    component: () => import(/* webpackChunkName: "contentviewer" */ '../views/ContentViewer.vue')
  },
  {
    path: '/content/pid/:pid',
    name: 'Ahora',
    props: true,
    component: () => import(/* webpackChunkName: "contentviewer" */ '../views/ContentViewer.vue')
  },
  {
    path: '/privacidad',
    name: 'Ahora',
    props: true,
    component: () => import(/* webpackChunkName: "contentviewer" */ '../views/Privacy.vue')
  },
  {
    path: '/',
    name: 'Login | Ahora Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/recuperar-cuenta',
    name: 'Recuperar cuenta | Ahora Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/cambiar-contrasena',
    name: 'Cambiar contraseña | Ahora Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/ChangePassword.vue')
  },

  // ADMIN ROUTES
  {
    path: '/secure/home',
    name: 'Home | Ahora Admin',
    meta: { requiresAuth : true },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/secure/numeros-importantes',
    name: 'Números importantes | Ahora Admin',
    meta: { requiresAuth : true },
    component: () => import(/* webpackChunkName: "numeros-importantes" */ '../views/Contacts.vue')
  },
  {
    path: '/secure/paginas/:pid',
    name: 'Listado de páginas | Ahora Admin',
    meta: { requiresAuth : true },
    props: true,
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue')
  },
  {
    path: '/secure/configuracion',
    name: 'Configuración | Ahora Admin',
    meta: { requiresAuth : true },
    props: true,
    component: () => import(/* webpackChunkName: "page" */ '../views/Configuration.vue')
  },
  {
    path: '/secure/perfil',
    name: 'Usuario | Ahora Admin',
    meta: { requiresAuth : true },
    props: true,
    component: () => import(/* webpackChunkName: "page" */ '../views/Profile.vue')
  },
  {
    path: '/secure/configuracion',
    name: 'Configuración | Ahora Admin',
    meta: { requiresAuth : true },
    component: () => import(/* webpackChunkName: "home" */ '../views/Configuration.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/authAction');

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const isLoggedIn = store.getters['auth/isLoggedIn'];

	if(requiresAuth && !isLoggedIn) {
		next('/');
	} 
  
  next();
});

router.afterEach((to) => {
	Vue.nextTick(() => document.title = to.name ?? 'Ahora Admin');
});

export default router
