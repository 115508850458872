//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import VueFroala from 'vue-froala-wysiwyg';

export const bus = new Vue();

Vue.config.productionTip = false;

Vue.mixin({
	methods: {
		mxShowMessage(text, color, dialog = false, timeout = 6000) {
			bus.$emit('alert', { text, color, timeout, dialog });
		},
  }
});

Vue.use( VueFroala );
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
