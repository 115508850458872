<template>
    <div class="alertWrapper">
        <template v-for="(alert, ix) in alerts">
            <div :class="'alert px-3 py-2 mb-2 rounded white--text elevation-3 ' + alert.color" :key="ix" v-if="!alert.dialog">
                <v-row no-gutters>
                    <v-col align-self="center">{{ alert.text }}</v-col>
                    <v-col cols="1" class="text-right">
                        <v-btn icon small color="white" @click="remove(alert.id)"><v-icon small>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>                
            </div>
            <v-dialog :value="true" v-else :key="ix" width="500" @click:outside="remove(alert.id)">
                <v-card>
                    <v-card-text v-html="alert.text"></v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed @click="remove(alert.id)">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template> 

<script>
import { bus } from '@/main';
export default {    
    data() {
        return {
            alerts: []
        }
    },
    mounted() {
        bus.$on( 'alert', (alert) => {
            this.createAlert(alert);
        });
    },
    methods: {
        createAlert(alert) {
            let id = this.createID();
            this.alerts.push({
                id,
                ...alert
            });

            if( !alert.dialog ) {
                setTimeout(() => { 
                    this.remove(id);
                }, alert.timeout??6000);
            }
        },
        remove(id) {
            let ix = this.alerts.findIndex(a => a.id == id);
            this.alerts.splice(ix, 1);
        },
        createID() {
            return '_' + Math.random().toString(36).substr(2, 9);
        }
    }
}
</script>

<style scoped>
.alertWrapper {
    position: fixed;
    bottom: 0;
    right: 10px;
    height: auto;
    z-index: 300;
}
.alertWrapper .alert {
    min-width: 300px;
    z-index: 300;
    position: relative;
}
.alertWrapper .v-snack {
    position: initial;
    margin: 10px 5px;
}
</style>